.owner-st, .title-st {
    color: #24326d;
}

.title-st3 {
    color: green;
}
.text-st {
    color: #000;
}
.hover-st:hover p {
    color: #0D6EFD;
}
