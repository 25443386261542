
  .container1 {
    background-color: #24326d;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    color: #fff;
    direction: ltr;
    height:auto;

    .map {
      height: 300px;
    }

    .address {

      a {
        color: #fff;
  
        &:hover {
          color: #4063ee;
        }
      }
    }
    .socialMedia {

      .socialMediaCol {
 
        a {
          color: #fff;

          &:hover {
            color: #4063ee;
          }
        }
      }
    }
 
  .copyRight {
  
    a {
      color: rgb(156, 146, 146);

      &:hover {
        color: #4063ee;
      }
    }

    p {
      color: rgb(156, 146, 146);

    }

}
 }