@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Catamaran:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hahmlet&display=swap');

.Ar {
    direction: rtl;
    font-family: 'Amiri', serif;

    .myStyle {
        direction: rtl !important;
        color: green;
    }

}
.De, .En {
    font-family: 'Hahmlet', serif;
}